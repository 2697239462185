import { useParams } from '@solidjs/router';
import { Show, onMount } from 'solid-js';
import IconLink from '~/assets/icons/link.png';
import IconTransUnionLogo from '~/assets/icons/transUnionLogo.png';
import { START_SCREENING, CONNECTING_TO_TRANS_UNION_BLURB, CONNECTING_TO_TRANS_UNION } from '~/assets/strings';
import IconTempCompanyLogo from '~/assets/tempCompanyLogo.png';
import ActivityIndicator from '~/components/common/activity-indicator/ActivityIndicator';
import { Button } from '~/components/common/buttons';
import usePresenter from '~/framework/hooks/usePresenter';
import useUseCase from '~/framework/hooks/useUseCase';
import { useLocalization } from '~/hooks/useLocalization';
import { RentalApplicationPresenter } from '~/presenters/RentalApplicationPresenter';
import { GetRentalApplicationUseCase } from '~/use-cases/rental-applications/getRentalApplicationUseCase';
import { LockRentalApplicationUseCase } from '~/use-cases/rental-applications/lockRentalApplicationUseCase';
import { StartRentalApplicationScreeningUseCase } from '~/use-cases/rental-applications/screening/startRentalApplicationScreeningUseCase';

export const RentalApplicationTransUnion = () => {
  const { t } = useLocalization();
  const params = useParams();

  const { model: presentableRentalApplication } = usePresenter(RentalApplicationPresenter);
  const { execute: getRentalApplication, isLoading: isLoadingApplication } = useUseCase(GetRentalApplicationUseCase);
  const { execute: lockRentalApplication, isLoading: isLockingApplication } = useUseCase(LockRentalApplicationUseCase);
  const { execute: startScreening, isLoading: isScreening } = useUseCase(StartRentalApplicationScreeningUseCase);

  const onClickStartQuestionnaire = () => {
    startScreening({ id: params.id, password: params.password });
  };

  onMount(async () => {
    await getRentalApplication({ id: params.id, password: params.password });
    await lockRentalApplication();
  });

  return (
    <Show
      when={!isLoadingApplication() && !isScreening() && !isLockingApplication()}
      fallback={
        <div class="flex h-section3">
          <ActivityIndicator />
        </div>
      }>
      <div class="flex h-section3 flex-col items-center justify-center bg-white p-6">
        <div class="my-8 flex h-section3 gap-3 md:w-3/4">
          <div class="flex size-full flex-col items-center justify-center">
            <div class="-mt-32 flex w-full flex-col items-center gap-10 text-center">
              <div class="md:w-96">
                <div class="relative flex h-32 justify-between rounded-full bg-white shadow-md">
                  <div class="absolute left-0 top-0 flex h-full w-2/3 items-center justify-start  rounded-full bg-gradient-to-r from-[#EFDBFF] to-[#FECBFF]/20">
                    <div class="bg-purple-200 ml-10 rounded-lg p-2">
                      <img src={IconTempCompanyLogo} alt="Temp Company Logo" />
                    </div>
                  </div>
                  <div class="absolute right-0 top-0 flex h-full w-2/3 items-center justify-end rounded-full bg-gradient-to-r from-[#DBE7FF]/20 to-[#AFCAFF]">
                    <div class="mr-3 rounded-lg">
                      <img class="-mt-2 h-16" src={IconTransUnionLogo} alt="Transunion" />
                    </div>
                  </div>
                  <div class="absolute left-1/2 top-1/2 flex size-28 -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-full bg-gray-100 p-2">
                    <img src={IconLink} alt="Link Icon" />
                  </div>
                </div>
              </div>
              <div class="flex flex-col items-center justify-center gap-2">
                <h1 class="text-base font-semibold text-text-level02 md:text-lg">{t(CONNECTING_TO_TRANS_UNION)}</h1>
                <p class="pt-5 text-xs font-normal text-text-level03 md:text-sm">{t(CONNECTING_TO_TRANS_UNION_BLURB)}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sticky bottom-0 left-0 w-full border-t border-gray-200 bg-white p-4">
        <div class="flex items-end justify-end">
          <Button onClick={onClickStartQuestionnaire} disabled={!presentableRentalApplication()?.isPaid}>
            {t(START_SCREENING)}
          </Button>
        </div>
      </div>
    </Show>
  );
};
